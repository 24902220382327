<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item label="商户">
        <el-input style="width: 100px;" v-model.trim="search.merchant" clearable class="merchant" />
      </el-form-item>
      <el-form-item label="姓名">
        <el-input style="width: 100px;" v-model.trim="search.name" clearable class="account" />
      </el-form-item>
      <el-form-item label="手机号">
        <el-input style="width: 170px;" v-model="search.mobile" clearable class="mobile" />
      </el-form-item>
      <el-form-item label="身份证号">
        <el-input style="width: 180px;" v-model.trim="search.idCard" clearable class="idCard" />
      </el-form-item>
      <el-form-item label="消费项目">
        <el-select v-model.trim="search.identity" filterable placeholder="请选择" clearable>
          <el-option v-for="item in reportList" :key="item.identity" :label="item.name" :value="item.identity">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker style="width: 380px;" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props:{
    reportList:{
      type:Array,
      default:()=>[]
    }
  },
  data () {
    return {
      search: {
        name: "",
        mobile: "",
        idCard: "",
        remarks: ""
      },
      date: [],
      // 状态选项
      statusOpts: [
        { value: "", label: "请选择" },
        { value: 0, label: "充值成功" },
        { value: 1, label: "充值失败" },
      ],
    };
  },
  methods: {
    searchList () {
      let [startTime = "", endTime = ""] = this.date || [];
      let param = Object.assign({ startTime, endTime }, this.search);
      this.$emit("search", param);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;

  .account.el-input {
    width: 120px;
  }

  .mobile.el-input {
    width: 180px;
  }

  .idCard.el-input {
    width: 180px;
  }

  .el-select {
    width: 130px;
  }
}
</style>
